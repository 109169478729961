import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Container } from "react-bootstrap";
import { CardTypes } from "../components/CardTypes";

export function CardsToRequest(){
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    
    return (
        <Container>
            <h1>Cards</h1>
            <AuthenticatedTemplate>
                <CardTypes />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <p>There is no cards information as you have not logged in yet.</p>
            </UnauthenticatedTemplate>
        </Container>
    );
};