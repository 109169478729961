import { useEffect, useState } from "react";
import { Accordion, Container } from "react-bootstrap";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../authConfig";
import { Link } from "react-router-dom";
import { ICardType } from "../components/CardTypes";
import { CardDisplay } from "../components/CardDisplay";

export interface ICardInfo{
    requestId: string,
    requestStatus: 	"request_submitted" | "issuance_error" | "request_retrieved" | "issuance_successful",
	state: string,
    timestamp: Date,
    cardTypeId: string
}

export function CardList (){
    const { instance } = useMsal();
    const [ cards, setCards ] = useState<ICardInfo[]>([]);
    const [ cardTypes, setCardTypes ] = useState<ICardType[]>([]);
    const [ isCardsListLoading, setIsCardsListLoading] = useState<boolean>(true);
    const [ isTypesListLoading, setIsTypesListLoading] = useState<boolean>(true);

    useEffect(() => {
        instance.acquireTokenSilent(tokenRequest).then(result => {
            fetch(process.env.REACT_APP_API_URL + "/cards/my", {
                method: 'GET',
                headers: {'Content-Type': 'application/json' , 'Authorization': `Bearer ${result.accessToken}`} 
              })    
                .then(response => {
                    if (response.ok){
                        console.log("Cards retrieved")
                        return response.json();
                    }else{
                        console.log(response.status + response.statusText)
                    }
                })
                .then(json => {
                    setCards(json as ICardInfo[]);
                    setIsCardsListLoading(false);
                })

            fetch(process.env.REACT_APP_API_URL + "/card-types", {
                method: 'GET',
                headers: {'Content-Type': 'application/json' , 'Authorization': `Bearer ${result.accessToken}`} 
            })    
                .then(response => response.json())
                .then(json => {
                    console.log(json);
                    setCardTypes(json.cardTypes as ICardType[]);
                    setIsTypesListLoading(false);
                })
        })
    }, [])

    const renderIssuedCards = () => {
        if (isCardsListLoading || isTypesListLoading){
            return <p>Loading your cards...</p>   
        }else if (cards.length > 0 && cardTypes.length > 0){
            return (
                cards.map(element => {
                    const matchingCardType = cardTypes.find(type => type.id === element.cardTypeId);
                    if (matchingCardType)
                        return <CardDisplay key={element.requestId} cardInfo={element} cardType={matchingCardType} />    
                })
            );
        }else{
            return (
                <>
                <p>You do not have any cards issued yet!</p>
                <p>Check out the <Link to="/cards/download">Downloads</Link> section to see if there is a card you can get...</p>
                </>
            );
        }
    };

    return (
        <Container>
            <h1>Your cards</h1>
            <AuthenticatedTemplate>
                <Accordion>{renderIssuedCards()}</Accordion>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <p>Cards can be viewed only if authenticated</p>
            </UnauthenticatedTemplate>
        </Container>
    );
}