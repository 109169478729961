import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import useInterval from "react-useinterval";
import { tokenRequest } from "../authConfig";
import { CardDownload } from "../components/CardDownload";
import { ICardIssuanceRequest, IIssuanceStatusMessage } from "../utils/issuanceInterfaces";

export function CardRequest(){
    const { instance } = useMsal();
    const { cardTypeId } = useParams();
    const [ issuanceStatus, setIssuanceStatus ]  = useState<IIssuanceStatusMessage>();
    const [ cardIssuanceRequest, SetCardIssuanceRequest ] = useState<ICardIssuanceRequest>();
    const [ state, setState ] = useState<"INIT" | "QR" | "ACCEPT" | "RESULTS">("INIT");
    const [ attemptsCount, setAttemptsCount ] = useState<number>(0);
    
    const requestStatusCheck = () => {
        if ((state === "QR" || state === "ACCEPT") && cardIssuanceRequest){
            instance.acquireTokenSilent(tokenRequest).then(result => {
                fetch(process.env.REACT_APP_API_URL + `/cards/issuance-requests/${cardIssuanceRequest.requestId}`, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json' , 'Authorization': `Bearer ${result.accessToken}`} 
                    })    
                    .then(response => {
                        if (response.ok){
                            console.log("Card issuance request retrieved")
                            return response.json();
                        }else{
                            console.log(response.status + response.statusText)
                        }
                    })
                    .then(json => {
                        const issuanceRequestStatus = json as IIssuanceStatusMessage;
                        if (issuanceRequestStatus.requestStatus === "request_retrieved"){
                            setState("ACCEPT");
                            setIssuanceStatus(issuanceRequestStatus);
                        }else if (
                            issuanceRequestStatus.requestStatus === "issuance_successful" ||
                            issuanceRequestStatus.requestStatus === "issuance_error" 
                        ){
                            setState("RESULTS");
                            setIssuanceStatus(issuanceRequestStatus);
                        }
                    })    
            })
        }
        setAttemptsCount(previous => previous + 1);
    };

    useEffect(() => {
        const payload = {
            id: cardTypeId
        };

        instance.acquireTokenSilent(tokenRequest).then(result => {
            fetch(process.env.REACT_APP_API_URL + "/cards/issuance-requests", {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {'Content-Type': 'application/json' , 'Authorization': `Bearer ${result.accessToken}`} 
              })    
                .then(response => {
                    if (response.ok){
                        console.log("Card creation initiated")
                        return response.json();
                    }else{
                        console.log(response.status + response.statusText)
                    }
                })
                .then(json => {
                    SetCardIssuanceRequest(json as ICardIssuanceRequest);
                    setState("QR");
                })
        })
    }, [])

    useInterval(requestStatusCheck, 3000);

    return (
        <Container>
            <h1>Card Request Processing</h1>
            <AuthenticatedTemplate>
            { state === "INIT" ? <p>Initiating card issuance...</p> : null }
            { (state === "QR" || state === "ACCEPT") && cardIssuanceRequest ? 
                <>
                <CardDownload cardIssuanceRequest={cardIssuanceRequest} state={state} />
                {/* (attempt {attemptsCount}) */}
                </> : null }
            { state === "RESULTS" && issuanceStatus && issuanceStatus.requestStatus === "issuance_successful" ?
                 <p>Congratulations on getting your card issued! See your cards in <Link to="/cards/list">Card list</Link> section.</p> : null }
            { state === "RESULTS" && issuanceStatus && issuanceStatus.requestStatus === "issuance_error" ?
                 <p>Something went wrong during acceptance and issuance: {issuanceStatus.error ?? "no error message provided" }</p> : null }
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <p>To do card requests, you need to be logged in.</p>
            </UnauthenticatedTemplate>
        </Container>
    );
};