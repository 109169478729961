import { Accordion, Button, Row } from "react-bootstrap";
import { ICardType } from "./CardTypes";
import styles from './CardType.module.css';

export interface ICardTypeProps{
    id: string,
    cardType: ICardType,
    canIssue: boolean,
    additionalInformation?: string
}

export const CardType = (props: ICardTypeProps) => {
    const id = props.id;
    const cardType = props.cardType;
    const canIssue = props.canIssue;
    const additionalInformation = props.additionalInformation;

    const cardStyle = {
        backgroundColor: cardType.display.card.backgroundColor,
        color: cardType.display.card.textColor
    };

    const cardRequestUrl = "/cards/download/" + cardType.id;

    return (
        <Accordion.Item eventKey={id}>  
            <Accordion.Header><strong>{cardType.name}</strong>{ additionalInformation ?? ""}</Accordion.Header>
            <Accordion.Body>
            <p>{cardType.display.card.description}</p>
            <div className={styles.card} style={cardStyle}>
                <div className={styles.cardLogo}>
                    <img src={cardType.display.card.logo.uri} className={styles.cardImage} alt="Logo" />
                </div>
                <div>
                    <div className={styles.cardName}>{cardType.display.card.title}</div>
                <div>
                    <div className={styles.cardIssuer}>{cardType.display.card.issuedBy}</div>
            </div></div>
            </div>
            <br/>
            { canIssue ? <Button variant="secondary" href={cardRequestUrl}>Request card</Button> : null }
            </Accordion.Body>
        </Accordion.Item>
    );
};

