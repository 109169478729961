import { Card, CardGroup, Container, Image, Row } from "react-bootstrap";
import { IPresentationStatusMessage } from "../utils/presentationInterfaces";
import styles from './CardPresentationResults.module.css';

interface ICardPresentationResultsProps{
    presentationStatus: IPresentationStatusMessage
}

export const CardPresentationResults = (props: ICardPresentationResultsProps) => {
    const presentationStatus = props.presentationStatus;

    return (
        <Container className={styles.container}>
            <br />
            <h2>Thank you for presenting your card!</h2>
            <br />
            { presentationStatus.verifiedCredentialsData ? 
            <>
            <CardGroup>
            <Card border="success" className={styles.card}>
                <Card.Header as="h5">Name and Surname</Card.Header>
                <Card.Body>
                    <Card.Title>{presentationStatus.verifiedCredentialsData[0].claims.firstName} {presentationStatus.verifiedCredentialsData[0].claims.lastName}</Card.Title>
                    <Card.Text>
                    These values are retrieved from the claims included in the verifiable credential data returned as part of the presentation.
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card border="success" className={styles.card}>
                <Card.Header as="h5">Revocation status</Card.Header>
                <Card.Body>
                    <Card.Title>{presentationStatus.verifiedCredentialsData[0].credentialState.revocationStatus}</Card.Title>
                    <Card.Text>
                    Status of the card presented is produced by checking the revocation information of the card.
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card border="success" className={styles.card}>
                <Card.Header as="h5">Issuer authority</Card.Header>
                <Card.Body>
                    <Card.Title>{presentationStatus.verifiedCredentialsData[0].issuer}</Card.Title>
                    <Card.Text>
                    ID of the Verified Credentials authority that issued the card presented.
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card border="success" className={styles.card}>
                <Card.Header as="h5">Validated domain</Card.Header>
                <Card.Body>
                    <Card.Title>{presentationStatus.verifiedCredentialsData[0].domainValidation.url}</Card.Title>
                    <Card.Text>
                    Validated domain name of the issuer.
                    </Card.Text>
                </Card.Body>
            </Card>
            </CardGroup>
            </>
            : <p>Unfortunately could not find verified credentials data...</p> }
        </Container>
    );
}