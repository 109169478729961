import { Configuration, LogLevel } from "@azure/msal-browser";


export const b2cPolicies = {
    names: {
        signUpSignIn: 'b2c_1_signupin',
        forgotPassword: 'b2c_1_reset',
        editProfile: 'b2c_1_edit',
    },
    authorities: {
        signUpSignIn: {
            authority: 'https://login.vilumi.eu/login.vilumi.eu/b2c_1_signupin',
        },
        forgotPassword: {
            authority: 'https://login.vilumi.eu/login.vilumi.eu/b2c_1_reset',
        },
        editProfile: {
            authority: 'https://login.vilumi.eu/login.vilumi.eu/b2c_1_edit',
        },
    },
    authorityDomain: 'login.vilumi.eu',
};

// Config object to be passed to Msal on creation
export const authConfig: Configuration = {
    auth: {
        clientId: '3d0d30e0-87d3-4e59-bd86-3251b894c8ee',
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
      system: {
        loggerOptions: {
          loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                return;
            }
          },
        },
      },
};

export const tokenRequest = {
  scopes: [
    "https://vilumieuexternal.onmicrosoft.com/card-service/cards.verify",
    "https://vilumieuexternal.onmicrosoft.com/card-service/cards.issue",
    "https://vilumieuexternal.onmicrosoft.com/card-service/cards.list"
  ],  
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

export const loginRequest = {
    scopes: [...tokenRequest.scopes, 'openid', 'profile', 'email']
};

export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@login.vilumi.eu"
};