import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { CardList } from './pages/CardList';
import { CardPresentationPage } from './pages/CardPresentationPage';
import { CardRequest } from './pages/CardRequest';
import { CardsToRequest } from './pages/CardsToRequest';
import { Home } from './pages/Home';
import { Token } from './pages/Token';

function App() {
  return (
    <>
      <Header />
      <br/>
      <Routes>
        <Route path="/" element={ <Home /> }/>
        <Route path="/token" element={ <Token /> }/>
        <Route path="/cards">
          {/* <Route index element={ <Cards /> } /> */}
          <Route path="download/" element={ <CardsToRequest /> } />
          <Route path="download/:cardTypeId" element={ <CardRequest /> } />
          <Route path="present/" element={ <CardPresentationPage /> } />
          <Route path="list/" element={ <CardList /> } />
        </Route>
      </Routes>
      <Footer />
    </>
    );
}

export default App;
