import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Button, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IdTokenData } from "../components/IdTokenData";
import { SignInButton } from "../components/SignInButton";
import { SignOutButton } from "../components/SignOutButton";
import { WelcomeUser } from "../components/WelcomeUser";

export function Home(){
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    
    return (
        <Container>
            <Row>
            <h1>Home</h1>
            <p>This is a test web site used to test and showcase various technical elements mostly related to identity management.</p>
            <p>As such it is presented as a fake community site for "Viļumi family" allowing users of the community log in, get various perks and also get verified credentials issued to them.</p>
            <p>Note that as this is a test site, use at your own risk.</p>
            </Row>
            <AuthenticatedTemplate>
            <Row>
                {activeAccount ? (
                    <Link to="/token">Check your token</Link>
                ) : ""}
            </Row>
            </AuthenticatedTemplate>
        </Container>
    );
};