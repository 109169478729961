import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Container, Row } from "react-bootstrap";
import { IdTokenData } from "../components/IdTokenData";

export function Token(){
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    
    return (
        <Container>
            <h1>Token information</h1>
            <AuthenticatedTemplate>
            <Row>
                {activeAccount ? (
                    <Container>
                        <IdTokenData idTokenClaims={activeAccount.idTokenClaims} /> 
                    </Container>
                ) : ""}
            </Row>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <p>There is no token information as you have not logged in yet.</p>
            </UnauthenticatedTemplate>
        </Container>
    );
};