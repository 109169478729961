import { IMsalContext, useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "react-bootstrap/Button";
import { IPublicClientApplication } from "@azure/msal-browser";

function signInClickHandler(instance: IPublicClientApplication) {
    instance.loginRedirect(loginRequest).catch(function (e) {
        console.log(e);
      });
  }

// SignInButton Component returns a button that invokes a popup login when clicked
export function SignInButton() {
    // useMsal hook will return the PublicClientApplication instance provided to MsalProvider
    const { instance } = useMsal();
  
    return <Button variant="secondary" className="ml-auto" onClick={() => signInClickHandler(instance)}>Sign In</Button>;
  }