import { Button, Container, Image } from "react-bootstrap";
import styles from './CardDownload.module.css';
import { AndroidView, BrowserView, IOSView, isAndroid, isIOS, isMobileOnly, MobileOnlyView, MobileView } from "react-device-detect";
import { ICardIssuanceRequest } from "../utils/issuanceInterfaces";

interface ICardDownloadProps{
    cardIssuanceRequest: ICardIssuanceRequest,
    state: "INIT" | "QR" | "ACCEPT" | "RESULTS"
}

export const CardDownload = (props: ICardDownloadProps) => {
    const cardIssuanceRequest = props.cardIssuanceRequest;
    const state = props.state;

    const renderAndDoAndroidRedirect = () => {
        // if (isMobileOnly && isAndroid){
        //     window.location.href = cardIssuanceRequest.url;
        //     setTimeout(function () { window.location.href = "https://play.google.com/store/apps/details?id=com.azure.authenticator"; }, 3000);
        // }

        return (
            <>
            <Button variant="secondary" href={cardIssuanceRequest.url}>Tap to redirect...</Button>
            </>
        );
    }

    const renderAndDoIOSRedirect = () => {
        // if (isMobileOnly && isIOS)
        //     setTimeout(function () { window.location.replace(cardIssuanceRequest.url); }, 2000); 

        return (
            <>
            <Button variant="secondary" href={cardIssuanceRequest.url}>Tap to redirect...</Button>
            </>
        );
    }

    return (
        <Container className={styles.container}>
            <h2>Download your issued card</h2>
            <BrowserView>
                { state === "QR" ? 
                    <>
                    <p>Please scan the QR code using Verified ID compatible wallet application.</p>
                    <Image src={cardIssuanceRequest?.qrCode} /> 
                    </>
                    : 
                    <>
                    <p>Please enter the PIN and accept the card.</p>
                    </>                    
                    }
                <p>Security PIN: {cardIssuanceRequest?.pin}</p>
                <p className={styles.small}>(in a full solution, PIN would get sent to you via SMS or e-mail, not shown here)</p>
                <div>
                    <a id="authenticatorAppIconControl-google-desktop" href="https://play.google.com/store/search?q=microsoft+authenticator&amp;c=apps&amp;utm_source=verified-id-demo&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1e">
                        <img alt="Get it on Google Play" className={styles.imageGoogle} src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
                    </a>
                    <a id="authenticatorAppIconControl-apple-desktop" href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458?itsct=apps_box_link&amp;itscg=30200">
                        <img alt="Download on the App Store"  className={styles.imageApple} src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;amp;releaseDate=1432944000&amp;amp;h=39686e6a537b2c44ff7ce60f6287e68f" />
                    </a>
                </div>
            </BrowserView>
            <MobileOnlyView>
                <p>Please use your mobile wallet to proceed...</p>
                <p>Security PIN: {cardIssuanceRequest?.pin}</p>
                <p className={styles.small}>(in a full solution, PIN would get sent to you via SMS or e-mail, not shown here)</p>
                <p className={styles.small}>(for this to work you will need a wallet app installed...)</p>
                <AndroidView>
                    <>
                    <div>
                    <a id="authenticatorAppIconControl-google-android" href="https://play.google.com/store/search?q=microsoft+authenticator&amp;c=apps&amp;utm_source=verified-id-demo&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1e">
                        <img alt="Get it on Google Play" className={styles.imageGoogle} src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
                    </a>
                    </div>
                    { renderAndDoAndroidRedirect() }
                    </>
                </AndroidView>
                <IOSView>
                    <>
                    <p className={styles.small}>(for this to work you will need a wallet app installed...)</p>
                    <div>
                    <a id="authenticatorAppIconControl-apple-ios" href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458?itsct=apps_box_link&amp;itscg=30200">
                        <img alt="Download on the App Store"  className={styles.imageApple} src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;amp;releaseDate=1432944000&amp;amp;h=39686e6a537b2c44ff7ce60f6287e68f" />
                    </a>
                    </div>
                    { renderAndDoIOSRedirect() }
                    </>
                </IOSView>
            </MobileOnlyView>
        </Container>
    );
}