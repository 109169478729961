import { useEffect, useState } from "react";
import useInterval from 'react-useinterval';
import { Container } from "react-bootstrap";
import { CardPresentation } from "../components/CardPresentation";
import { ICardPresentationRequest, IPresentationStatusMessage } from "../utils/presentationInterfaces";
import { CardPresentationResults } from "../components/CardPresentationResults";

export function CardPresentationPage(){
    const [ cardPresentationRequest, setCardPresentationRequest ] = useState<ICardPresentationRequest>();
    const [ presentationStatus, setPresentationStatus ]  = useState<IPresentationStatusMessage>();
    const [ state, setState ] = useState<"INIT" | "QR" | "PRESENT" | "RESULTS">("INIT");
    const [ attemptsCount, setAttemptsCount ] = useState<number>(0);
 
    const requestStatusCheck = () => {
        if ((state === "QR" || state === "PRESENT") && cardPresentationRequest){
            fetch(process.env.REACT_APP_API_URL + `/cards/presentation-requests/${cardPresentationRequest.requestId}`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json'} 
                })    
                .then(response => {
                    if (response.ok){
                        console.log("Card verification request retrieved")
                        return response.json();
                    }else{
                        console.log(response.status + response.statusText)
                    }
                })
                .then(json => {
                    const presentationRequestStatus = json as IPresentationStatusMessage;
                    if (presentationRequestStatus.requestStatus === "request_retrieved"){
                        setState("PRESENT");
                        setPresentationStatus(presentationRequestStatus);
                    }else if (presentationRequestStatus.requestStatus === "presentation_verified"){
                        setState("RESULTS");
                        setPresentationStatus(presentationRequestStatus);
                    }
                })    
        }
        setAttemptsCount(previous => previous + 1);
    };

    useEffect(() => {
        const payload = {
        };

        fetch(process.env.REACT_APP_API_URL + "/cards/presentation-requests", {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {'Content-Type': 'application/json'} 
            })    
            .then(response => {
                if (response.ok){
                    console.log("Card verification initiated")
                    return response.json();
                }else{
                    console.log(response.status + response.statusText)
                }
            })
            .then(json => {
                setCardPresentationRequest(json as ICardPresentationRequest);
                setState("QR");
            })        
    }, [])

    useInterval(requestStatusCheck, 3000);

    return (
        <Container>
            <h1>Card Presentation</h1>
            { state === "INIT" ? <p>Initiating card presentation...</p> : null }
            { state === "QR" && cardPresentationRequest ? 
                <>
                <CardPresentation cardPresentationRequest={cardPresentationRequest} />
                {/* (attempt {attemptsCount}) */}
                </> : null }
            { state === "PRESENT" ? <p>Waiting for card to be shared...</p> : null }
            { state === "RESULTS" && presentationStatus ? <CardPresentationResults presentationStatus={presentationStatus} /> : null }
        </Container>
    );
};