import { Container, Navbar } from "react-bootstrap";

// header includes the some basic info on the bottom of the page
export function Footer () {
    return (
        <Navbar bg="dark" variant="dark" expand="lg" fixed="bottom">
        <Container>
            <Navbar.Text>Use at your own risk. &copy; Viļumi family. Version: {process.env.REACT_APP_VERSION}</Navbar.Text>
        </Container>
        </Navbar>      
    );
}