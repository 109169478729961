import { Button, Container, Image } from "react-bootstrap";
import { AndroidView, BrowserView, IOSView, isAndroid, isIOS, isMobileOnly, MobileOnlyView } from "react-device-detect";
import { ICardPresentationRequest } from "../utils/presentationInterfaces";
import styles from './CardPresentation.module.css';

interface ICardPresentationProps{
    cardPresentationRequest: ICardPresentationRequest
}

export const CardPresentation = (props: ICardPresentationProps) => {
    const cardPresentationRequest = props.cardPresentationRequest;

    const renderAndDoAndroidRedirect = () => {
        //if (isMobileOnly && isAndroid)
        //    setTimeout(function () { window.location.href = cardPresentationRequest.url; }, 2000);

        return (
            <>
            <Button variant="secondary" href={cardPresentationRequest.url}>Tap to redirect...</Button>
            </>
        );
    }

    const renderAndDoIOSRedirect = () => {
        //if (isMobileOnly && isIOS)
        //    setTimeout(function () { window.location.replace(cardPresentationRequest.url); }, 2000); 

        return (
            <>
            <Button variant="secondary" href={cardPresentationRequest.url}>Tap to redirect...</Button>
            </>
        );
    }

    return (
        <Container className={styles.container}>
            <h2>Present your card</h2>
            <BrowserView>
                <p>Please scan the QR code using Verified ID compatible wallet application.</p>
                <Image src={cardPresentationRequest?.qrCode} />
                <p className={styles.small}>(use your wallet app with cards you have)</p>
            </BrowserView>           
            <MobileOnlyView>
                <p>Please use your Verified ID compatible wallet application to share your matching credential.</p>
                {/* <p className={styles.small}>(you will be redirected shortly)</p> */}
                <AndroidView>{renderAndDoAndroidRedirect()}</AndroidView>
                <IOSView>{renderAndDoIOSRedirect()}</IOSView>
            </MobileOnlyView>
        </Container>
    );
}