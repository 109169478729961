import { IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";

function signOutClickHandler(instance: IPublicClientApplication) {
    const logoutRequest = {
      account: instance.getActiveAccount(),
      mainWindowRedirectUri: "/",
      postLogoutRedirectUri: "/",
    };
    instance.logoutRedirect(logoutRequest);
  }
  
export function SignOutButton() {
    // useMsal hook will return the PublicClientApplication instance provided to MsalProvider
    const { instance } = useMsal();
  
    return (
      <Button variant="secondary" className="ml-auto" onClick={() => signOutClickHandler(instance)}>Sign Out</Button>
    );
}