import { ICardType } from "./CardTypes";
import { ICardInfo } from "../pages/CardList";
import { CardType } from "./CardType";

interface ICardDisplayProps{
    cardInfo: ICardInfo,
    cardType: ICardType
}

export const CardDisplay = (props: ICardDisplayProps) => {
    const cardInfo = props.cardInfo;
    const cardType = props.cardType;

    const issuedCardInformation = `: Issued ${new Date(cardInfo.timestamp).toLocaleDateString()}`;

    return <CardType id={cardInfo.requestId} cardType={cardType} canIssue={false} additionalInformation={issuedCardInformation} />
};